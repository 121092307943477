.accordion {
  border-radius: $border-radius;

  > .card {
    > .card-header {
      @include site-font;
      background-color: lighten($lighter, 1.5%);
      border-bottom: 1px solid $light-shade;
      position: relative;
      cursor: pointer;
    }

    > .card-footer {
      border-bottom: 1px solid $light-shade;
      background-color: transparent;

      .badge {
        cursor: pointer;
      }
    }

    &.active > .card-header {
      background-color: $lighter;
      color: $primary;
      border-top: 1px solid darken($light, 9%);
    }

    &:first-of-type.active > .card-header {
      border-top: 0;
    }
  }
}
