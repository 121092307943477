.card {
  border: 0;
  overflow: hidden;

  .card-header {
    border: 0;
    padding: 1rem 0.75rem;
  }

  .card-footer {
    border-top: 0;
    padding: 1rem;
  }
}
