// Setup Bootstrap vars
@import './Assets/Scss/variables/bs-vars';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Mixins
@import './Assets/Scss/mixins/mixins';

// Partials
@import './Assets/Scss/partials/common';
@import './Assets/Scss/partials/typography';
@import './Assets/Scss/partials/icons';
@import './Assets/Scss/partials/cards';
@import './Assets/Scss/partials/accordions';

// Components
@import './Assets/Scss/components/header';
@import './Assets/Scss/components/footer';
@import './Assets/Scss/components/loading';
@import './Assets/Scss/components/headerNav';

// Pages
@import './Assets/Scss/pages/FAQ';
@import './Assets/Scss/pages/Home';
@import './Assets/Scss/pages/Parks';
@import './Assets/Scss/pages/Park';
@import './Assets/Scss/pages/Terms';

// Libs
@import './Assets/Scss/libs/slick';
@import './Assets/Scss/libs/slick-theme';

// html
html {
  scroll-behavior: smooth;
}

// Layout
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content, main {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

:root {
  --spacing-0: 0rem;
  --spacing-1: #{0.25 * $spacer};
  --spacing-2: #{0.5 * $spacer};
  --spacing-3: #{1 * $spacer};
  --spacing-4: #{1.5 * $spacer};
  --spacing-5: #{3 * $spacer};
}