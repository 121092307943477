.home-hero-container {
  overflow: hidden;
  max-width: calc(100%);
  padding: 0;
  width: 100%;

  .home-hero {
    height: 80vh;
    min-height: 650px;
    background-size: cover;
    //background-attachment: fixed;
    background-position: 100% 50%;

    @include media-breakpoint-down(sm) {
      height: 45vh;
      min-height: 500px;
    }
  }
}

.waves {
  aspect-ratio: 15/1;
  width: 100%;
  //height: 5vw;
  background-repeat: no-repeat;
  background-position: bottom;
  //background-size: 100vw 5vw;
  background-image: url('../../images/waves.svg');

  @include media-breakpoint-down(md) {
    aspect-ratio: 10/1;
  }

  &.dark {
    background-image: url('../../images/waves-dark.svg');
  }

  &.warning {
    background-image: url('../../images/waves-warning.svg');
  }

  &.light {
    background-image: url('../../images/waves-light.svg');
  }

  &.flip {
    transform: scale(-1, -1);
  }
}

.homepage-card {
  img {
    height: 200px;
    object-fit: cover;
  }

  .card-text {
    margin: 0;
    p {
      &:last-child {
        margin: 0;
      }
    }
  }
}

.pb-5 div:nth-of-type(2) .card-footer .btn {
  //    display:none
}
