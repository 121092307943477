@font-face {
  font-family: 'Tim Icons';
  src: url('../../fonts/tim-icons.woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$icon-sizes: 50, 75, 125, 150, 175, 200, 300;

[class*='icon-'] {
  @include icon-font;

  @each $size in $icon-sizes {
    &.size-#{$size} {
      font-size: #{$size}#{'%'};
    }

    &.scale-#{$size} {
      display: inline-block;
      transform-origin: center;
      transform: scale(#{$size * 0.01});
    }
  }
}

.icon-font {
  @include icon-font;

  &[data-char='_'] {
    color: #a62634;
  }
  &[data-char='['] {
    color: #e51580;
  }
  &[data-char=']'] {
    color: #e02441;
  }
  &[data-char='`'] {
    color: #e3ac7a;
  }
  &[data-char='^'] {
    color: #4860aa;
  }
  &[data-char='a'] {
    color: #9e8d7a;
  }
  &[data-char='b'] {
    color: #da662b;
  }
  &[data-char='c'] {
    color: #00a29c;
  }
  &[data-char='d'] {
    color: #1d6e3d;
  }
  &[data-char='e'] {
    color: #b72786;
  }
  &[data-char='g'] {
    color: #4565ae;
  }
  &[data-char='k'] {
    color: #7e464e;
  }
  &[data-char='N'] {
    color: #df1d84;
  }
  &[data-char='O'] {
    color: #632681;
  }
  &[data-char='P'] {
    color: #f39207;
  }
  &[data-char='Q'] {
    color: #d4222e;
  }
  &[data-char='R'] {
    color: #e2a728;
  }
  &[data-char='S'] {
    color: #f28e28;
  }
  &[data-char='T'] {
    color: #b72786;
  }
  &[data-char='U'] {
    color: #8ca73b;
  }
  &[data-char='V'] {
    color: #f18c83;
  }
  &[data-char='W'] {
    color: #7e358a;
  }
  &[data-char='X'] {
    color: #29ade3;
  }
  &[data-char='Y'] {
    color: #2e7bc0;
  }
}

$icons: (
  heart-solid: '\0041',
  heart: '\0042',
  left: '\0043',
  left-solid: '\0044',
  right: '\0045',
  right-solid: '\0046',
  up: '\0047',
  up-solid: '\0048',
  info: '\0049',
  grid: '\004a',
  slider: '\004b',
  filter: '\004c',
  pin: '\004d',
  close: '\0078',
  delete: '\0078',
  text: '\003e',
);

@each $name, $char in $icons {
  .icon-#{$name}::before {
    content: $char;
  }
}
