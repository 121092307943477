// Sass file for global CSS rules

.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.box-shadow {
  @include site-box-shadow(rgba($dark, 0.1));
}

.box-shadow-top {
  @include site-box-shadow(rgba($dark, 0.075), -0.3rem, 1rem);
}

@include media-breakpoint-down(sm) {
  .box-shadow {
    @include site-box-shadow(rgba($dark, 0.1), 0.3rem, 0.3rem);
  }
}

// Saved Parks animation
.save-park {
  background-color: transparent;
  background-image: radial-gradient($white 0%, rgba(255, 255, 255, 0) 66%);
  border-radius: 99px;
  border: 0;
  outline: none;

  small {
    white-space: nowrap;
  }
  
  &:focus {
    outline: none;
  }

  i {
    &::before {
      color: $dark;
      display: block;
      transform: scale(0.9) rotate(0);
      transition: color 0.5s, transform 0.5s, -webkit-transform 0.5s;
    }
  }

  &.saved {
    color: $warning;

    i {
      &::before {
        color: $warning;
        text-shadow: 0 1px 1px $white, 0 -1px 1px $white, 0 0 0.5rem transparentize($warning, 0.5);
        transform: scale(1.25) rotate(360deg);
        transform-origin: center;
        transition: color 0.5s, transform 0.5s;
      }
    }
  }
}

// Custom BG's
.bg-light-to-lighter {
  background-image: linear-gradient($lighter, $light);
}

// Slider
.slick-slider {
  .slick-slide {
    img {
      border-radius: $border-radius;
      aspect-ratio: 4/3;
    }
  }
}

// Border radius
.border-radius {
  border-radius: $border-radius;
}

.border-bottom-2 {
  border-bottom: 0.0625rem solid darken($light, 10%);
}

// Tooltip
.tooltip {
  &.bg-warning {
    background-color: transparent !important;

    .tooltip-inner {
      background-color: $warning;
      color: $dark;
    }
  }

  &.bs-tooltip-left {
    .arrow {
      &::before {
        border-left-color: $warning !important;
      }
    }
  }

  &.bs-tooltip-bottom {
    .arrow {
      &::before {
        border-bottom-color: $warning;
      }
    }
  }
}

.form-control:focus {
  box-shadow: 0 0 5px gray !important;
  border-color: inherit;  /* Ensure border doesn't change */
  outline: none;
}

.attraction-card {
  flex: 1;
  display: flex;
  flex-direction: column;

  .card-header {
    flex-grow: 1;
  }
}
