// Color system
$white: #fff;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000;

$grays: ();
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$red: #e01600;
$yellow: #ffae00;
$green: #74b62e;

$colors: ();
$colors: map-merge(
  (
    'red': $red,
    'yellow': $yellow,
    'green': $green,
    'white': $white,
    'black': $black,
  ),
  $colors
);

$dark: #232327;
$light: #f7f4f4;
$light-shade: darken($light, 3%);
$lighter: lighten($light, 1.5%);
$primary: $red;
$secondary: $dark;
$success: $green;
$info: lighten($yellow, 10%);
$warning: $yellow;
$danger: darken($red, 10%);

$theme-colors: ();
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'light-shade': $light-shade,
    'lighter': $lighter,
    'dark': $dark,
  ),
  $theme-colors
);

// Grid
$container-max-widths: (
  xl: 1400px,
);

// Body
$body-bg: $light;
$body-color: $dark;

// Card
$card-cap-bg: $white;

// Dropdown
$dropdown-bg: $dark;
$dropdown-link-color: $white;
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: lighten($dark, 5%);

// hr
$hr-border-color: $light;

// Spacer
$spacer: 1rem;

// Border
$border-color: darken($light, 5%);

// Border Radius
$border-radius: 0.33rem;

// Fonts
//$font-family-sans-serif: "The Sun Regular", "Segoe UI";
$headings-font-family: 'The Sun Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-weight-normal: 200;
$line-height-base: 1.6;
$body-color: $dark;
$font-weight-bolder: bold;

// Navbar
$navbar-padding-y: $spacer;
$navbar-light-color: $dark;
$navbar-light-toggler-border-color: transparent;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$white}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color: transparent;
$dropdown-link-active-bg: transparent;

// Badges
$badge-font-weight: 400;
$badge-padding-y: 0.5em;
$badge-padding-x: 0.5em;

// Buttons
$btn-font-weight: 500;
$btn-padding-y: 0.5rem;
$btn-padding-x: 1rem;
$btn-font-size: 0.9rem;
//$btn-border-radius: 99px;
