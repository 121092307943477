$basic-spinner-dimensions: 4rem; //width and height of spinner
$main-spinner-dimensions: $basic-spinner-dimensions - 0.25rem * 2; //width and height of bigger circle
$small-spinner-dimensions: $main-spinner-dimensions * 0.7; //width and height of smaller circle

.spinner {
  position: relative;
  width: $basic-spinner-dimensions;
  height: $basic-spinner-dimensions;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-width: 4px;
    border-style: solid;
    border-radius: 50%;
  }

  &.spinner-1 {
    @keyframes rotate-animation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes anti-rotate-animation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(-360deg);
      }
    }

    &::before {
      width: $main-spinner-dimensions;
      height: $main-spinner-dimensions;
      border-bottom-color: $dark;
      border-right-color: $dark;
      border-top-color: transparent;
      border-left-color: transparent;
      top: 0;
      left: 0;
      animation: rotate-animation 1.5s linear 0s infinite;
    }

    &:after {
      width: $small-spinner-dimensions;
      height: $small-spinner-dimensions;
      border-bottom-color: $dark;
      border-right-color: $dark;
      border-top-color: transparent;
      border-left-color: transparent;
      top: ($main-spinner-dimensions - $small-spinner-dimensions) * 0.5;
      left: ($main-spinner-dimensions - $small-spinner-dimensions) * 0.5;
      animation: anti-rotate-animation 1s linear 0s infinite;
    }
  }
}
