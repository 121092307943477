.header-nav {
  border-bottom: 1px solid rgb(223, 224, 228);
  position: sticky;
  top: 63px; // 64px - 1 to hide the gap
  background-color: white;
  z-index: 10;
  display: flex;
  
  ul {
    list-style-type: none;
    padding-inline-start: 0px;
    display: flex;
    justify-content: flex-start;
    row-gap: 16px;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
  }

  ul::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .header-nav-item {
    &:hover,
    &.active {
      text-decoration: underline;
      text-underline-offset: 12px;
      text-decoration-thickness: 3px;
      text-decoration-color: $primary;
    }
  }
}
