.navbar {
  .navbar-brand {
    background-color: $primary;
    width: 65%;
    max-width: 18rem;
    padding: 0.5rem 1.5rem;

    .sun-logo,
    .club-logo,
    .club-logo15 {
      display: inline-block;
      text-indent: -999px;
      //width: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .club-logo,
    .club-logo15 {
      height: 3rem;
      width: 100%;
    }
    .sun-logo {
      background-image: url('../../images/thesun.svg');
      height: 1.5rem;
      transform: translateY(0.25rem);
      width: 40%;
      background-position: right;
      margin-right: 2px;
    }
    .club-logo {
      background-image: url('https://content.breakfreeholidays.co.uk/images/affiliates/sun950/950logo2.png');
    }
    .club-logo15 {
      background-image: url('../../images/sun-logo-15.png');
    }
  }

  .navbar-nav {
    min-height: 44px; // Helps with CLS metric.
  }

  // HAMBURGER MENU
  #nav-toggle {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    cursor: pointer;
    outline: none;
    margin-right: 1rem;

    span {
      transform: translateX(12px);
      &::before {
        top: -9px;
        transform: rotate(0);
      }

      &::after {
        bottom: -9px;
        transform: rotate(0);
      }
    }

    span,
    span::before,
    span::after {
      cursor: pointer;
      height: 2px;
      width: 26px;
      background: $white;
      position: absolute;
      display: block;
      content: '';
      transition: all 0.3s ease-in-out;
    }

    &:not(.collapsed) {
      span {
        background-color: transparent;
        &::before {
          transform: rotate(45deg);
          transform-origin: top left;
        }

        &::after {
          transform: rotate(-45deg);
          transform-origin: bottom left;
        }
      }
    }
  }

  .nav-link {
    font-weight: bold;
    position: relative;

    .line-height-fix {
      line-height: 2.5rem;
    }

    &.active {
      &::after {
        content: '';
        display: block;
        background-color: $primary;
        position: absolute;
        bottom: -0.125rem;
        left: 50%;
        margin-left: -0.75rem;
        width: 1.5rem;
        height: 0.25rem;
        border-radius: 0.125rem;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }
}
