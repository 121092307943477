@include font-face('The Sun Bold', TheSun-Bold);
@include font-face('Tim Icons', tim-icons);

// Text classes
.text-shadow-dark {
  text-shadow: 0 0.125rem 0.25rem $dark;
}

.text-shadow-white {
  text-shadow: 0 0.05rem 0.05rem $white;
}

.site-font {
  @include site-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  small {
    font-family: $font-family-sans-serif;

    &.smaller {
      font-size: 50%;
    }
  }
}

@each $size, $length in $spacers {
  .font-size-#{$size} {
    font-size: $length !important;
  }

  @include media-breakpoint-down(sm) {
    .font-size-sm-#{$size} {
      font-size: $length !important;
    }
  }
}
 
h1 {
  font-size: 40px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 26px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}