// Fonts
@mixin font-face($font-family, $font-file) {
  $font-path: './../../fonts';
  $filepath: $font-path + '/' + $font-file;
  $font-display: 'swap'; // swap | block

  @font-face {
    font-family: quote($font-family);
    src: url(quote($filepath + '.woff2')) format('woff2');
    font-display: quote($font-display);
  }
}

@mixin site-font {
  font-family: 'The Sun Bold', sans-serif;
}

@mixin icon-font {
  text-emphasis: none;
  font-family: 'Tim Icons', sans-serif;
  font-style: normal;
}

@mixin site-box-shadow($colour: rgba($light, 1), $y: 0.5rem, $blur: 0.5rem) {
  box-shadow: 0 $y $blur $colour;
}
